<template>
  <base-layout-two
    page-title="EPDS Questionnaire"
    :page-default-back-link="`/users/${$route.params.id}`"
  >
    <section class="ion-padding background-white">
      <h1>{{ formatDate(epds_questionnaire.created_at, "MMMM do, yyyy") }}</h1>
      <span class="p">
        Total Score: {{ epds_questionnaire.score_total }}<br />
        Anxiety Score: {{ epds_questionnaire.score_anxiety }}<br />
        SI Score: {{ epds_questionnaire.score_si }}
      </span>
    </section>

    <div class="padding-8">
      <base-card title="EPDS Answers">
        <base-field-display
          label-text="1. I have been able to laugh and see the funny side of things"
          class="padding-bottom-24"
        >
          <p>{{ q1Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="2. I have looked forward with enjoyment to things"
          class="padding-bottom-24"
        >
          <p>{{ q2Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="3. I have blamed myself unnecessarily when things went wrong"
          class="padding-bottom-24"
        >
          <p>{{ q3Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="4. I have been anxious or worried for no good reason"
          class="padding-bottom-24"
        >
          <p>{{ q4Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="5. I have felt scared or panicky for no very good reason"
          class="padding-bottom-24"
        >
          <p>{{ q5Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="6. Things have been getting on top of me"
          class="padding-bottom-24"
        >
          <p>{{ q6Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="7. I have been so unhappy that I have had difficulty sleeping"
          class="padding-bottom-24"
        >
          <p>{{ q7Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="8. I have felt sad or miserable"
          class="padding-bottom-24"
        >
          <p>{{ q8Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="9. I have been so unhappy that I have been crying"
          class="padding-bottom-24"
        >
          <p>{{ q9Answer }}</p>
        </base-field-display>

        <base-field-display
          label-text="10. The thought of harming myself has occurred to me"
          class="padding-bottom-24"
        >
          <p>{{ q10Answer }}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
import { formatDate } from "@/util/helpers";

export default {
  data() {
    return {
      epds_questionnaire: {},
      formatDate,
    };
  },

  computed: {
    q1Answer() {
      if (this.epds_questionnaire.q1 === "a")
        return "A. As much as I always could";
      else if (this.epds_questionnaire.q1 === "b")
        return "B. Not quite so much now";
      else if (this.epds_questionnaire.q1 === "c")
        return "C. Definitely not so much now";
      else if (this.epds_questionnaire.q1 === "d") return "D. Not at all";

      return "";
    },
    q2Answer() {
      if (this.epds_questionnaire.q2 === "a") return "A. As much as I ever did";
      else if (this.epds_questionnaire.q2 === "b")
        return "B. Rather less than I used to";
      else if (this.epds_questionnaire.q2 === "c")
        return "C. Definitely less than I used to";
      else if (this.epds_questionnaire.q2 === "d") return "D. Hardly at all";

      return "";
    },
    q3Answer() {
      if (this.epds_questionnaire.q3 === "a") return "A. Yes, most of the time";
      else if (this.epds_questionnaire.q3 === "b")
        return "B. Yes, some of the time";
      else if (this.epds_questionnaire.q3 === "c") return "C. Not very often";
      else if (this.epds_questionnaire.q3 === "d") return "D. No, never";

      return "";
    },
    q4Answer() {
      if (this.epds_questionnaire.q4 === "a") return "A. No not at all";
      else if (this.epds_questionnaire.q4 === "b") return "B. Hardly ever";
      else if (this.epds_questionnaire.q4 === "c") return "C. Yes, sometimes";
      else if (this.epds_questionnaire.q4 === "d") return "D. Yes, very often";

      return "";
    },
    q5Answer() {
      if (this.epds_questionnaire.q5 === "a") return "A. Yes, quite a lot";
      else if (this.epds_questionnaire.q5 === "b") return "B. Yes, sometimes";
      else if (this.epds_questionnaire.q5 === "c") return "C. No, not much";
      else if (this.epds_questionnaire.q5 === "d") return "D. No, not at all";

      return "";
    },
    q6Answer() {
      if (this.epds_questionnaire.q6 === "a")
        return "A. Yes, most of the time I haven't been able to cope at all";
      else if (this.epds_questionnaire.q6 === "b")
        return "B. Yes, sometimes I haven't been coping as well as usual";
      else if (this.epds_questionnaire.q6 === "c")
        return "C. No, most of the time I have coped quite well";
      else if (this.epds_questionnaire.q6 === "d")
        return "D. No, I have been coping as well as ever";

      return "";
    },
    q7Answer() {
      if (this.epds_questionnaire.q7 === "a") return "A. Yes, most of the time";
      else if (this.epds_questionnaire.q7 === "b") return "B. Yes, sometimes";
      else if (this.epds_questionnaire.q7 === "c") return "C. Not very often";
      else if (this.epds_questionnaire.q7 === "d") return "D. No, not at all";

      return "";
    },
    q8Answer() {
      if (this.epds_questionnaire.q8 === "a") return "A. Yes, most of the time";
      else if (this.epds_questionnaire.q8 === "b") return "B. Yes, quite often";
      else if (this.epds_questionnaire.q8 === "c") return "C. Not very often";
      else if (this.epds_questionnaire.q8 === "d") return "D. No, not at all";

      return "";
    },
    q9Answer() {
      if (this.epds_questionnaire.q9 === "a") return "A. Yes, most of the time";
      else if (this.epds_questionnaire.q9 === "b") return "B. Yes, quite often";
      else if (this.epds_questionnaire.q9 === "c")
        return "C. Only occasionally";
      else if (this.epds_questionnaire.q9 === "d") return "D. No, never";

      return "";
    },
    q10Answer() {
      if (this.epds_questionnaire.q10 === "a") return "A. Yes, quite often";
      else if (this.epds_questionnaire.q10 === "b") return "B. Sometimes";
      else if (this.epds_questionnaire.q10 === "c") return "C. Hardly ever";
      else if (this.epds_questionnaire.q10 === "d") return "D. Never";

      return "";
    },
  },

  ionViewWillEnter() {
    this.fetchEpdsQuestionnaire();
  },

  methods: {
    async fetchEpdsQuestionnaire() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/epds_questionnaires/${this.$route.params.epds_questionnaire_id}`
        )
        .then((response) => {
          this.epds_questionnaire = response.data.epds_questionnaire;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>